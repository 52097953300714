import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Message, Grid, Popup, Divider, Form } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';

import Modal from '../../components/Modal';
import Avatar from '../../components/Avatar';
import Page from '../../components/Page';
import Pagination from '../../components/Pagination';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import Timer from '../../components/Timer';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 40;

const entityTypes = organizationType => ({
  STUDENT: utils.checkIfItIsClientOrSchool(organizationType) === 1 ? __('Student') : __('Client'),
  STAFF: __('Staff'),
  ADMIN: __('Admin')
});

@inject('store')
@graphql(gql`query UserInvitationsQuery (
  $limit: Int,
  $offset: Int
) {
  viewer {
    id: dbId
    selected
    invitations (limit: $limit, offset: $offset) {
      nodes {
        id: dbId
        selected (selected: false)
        type
        entity {
          id: dbId
          fullname
          type
          picture {
            uri
            id: dbId
            key
          }
          organization {
            id: dbId
            fullname
            type
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
}`, {
  options: {
  fetchPolicy: 'network-only',
  variables: {
  limit: PAGE_SIZE,
  offset: 0
  }
  }
  })
@graphql(gql`mutation createUserEntity($createUserEntityMutation: CreateUserEntityInput!) {
  createUserEntity(input: $createUserEntityMutation) {
    clientMutationId
  }
}`, {
  name: 'createUserEntity',
  options: {
  refetchQueries: ['UserInvitationsQuery', 'AppQuery', 'UserNotificationsQuery']
  }
  })
@graphql(gql`mutation createConfirmAddress($createConfirmAddressMutation: CreateConfirmAddressInput!) {
  createConfirmAddress(input: $createConfirmAddressMutation) {
    clientMutationId
  }
}`, {
  name: 'createConfirmAddress'
  })
@graphql(gql`mutation confirmAddress($confirmAddressMutation: ConfirmAddressInput!) {
  confirmAddress(input: $confirmAddressMutation) {
    clientMutationId
  }
}`, {
  name: 'confirmAddress'
  })
@observer
export default class UserInvitations extends Page {
  state = {
    step: 0,
    noConfirmedInvitations: [],
    confirmSent: {
      EMAIL: 0,
      PHONE: 0
    },
    code: '',
    attempt: 0
  }

  sendConfirmation = (type) => {
    const attempt = this.state.attempt + 1;

    this.props.createConfirmAddress({
      variables: {
        createConfirmAddressMutation: {
          addressType: type,
          isNewCode: true
        }
      }
    }).then(() => {
      const confirmSent = this.state.confirmSent;
      confirmSent[type] += 1;
      this.setState({ confirmSent, attempt });
    });
  }

  confirmAddress = (code, noConfirmedInvitations) => this.props.confirmAddress({
    variables: {
      confirmAddressMutation: {
        code
      }
    }
  }).then(async () => {
    await Promise.all(noConfirmedInvitations.map(invitation => this.props.createUserEntity({
      variables: {
        createUserEntityMutation: {
          entityId: invitation.entity.id
        }
      }
    }))).then(() => {
      this.props.data.refetch();
      this.props.store.snackbar = {
        active: true,
        message: noConfirmedInvitations.length > 1 ? __('%s people added to your account', noConfirmedInvitations.length) : __('%s added to your account', noConfirmedInvitations[0].entity.fullname),
        success: true
      };
    });

    this.props.onSubmit();
  }).catch((err) => {
    const error = utils.handleError(err.graphQLErrors[0]);
    this.setState({ error });
    this.props.store.snackbar = { active: true, message: error, success: false };
  })

  onSubmit = async () => {
    const { store, data } = this.props;
    const acceptedInvitations = data.viewer.invitations.nodes.filter(invitation => invitation.selected);
    const confirmedInvitations = [];
    const noConfirmedInvitations = [];

    acceptedInvitations.forEach((invitation) => {
      if ((invitation.type === 'PHONE' && store.currentUser.confirmPhone) || (invitation.type === 'EMAIL' && store.currentUser.confirmEmail)) {
        confirmedInvitations.push(invitation);
      } else {
        noConfirmedInvitations.push(invitation);
      }
    });

    if (confirmedInvitations.length) {
      await Promise.all(confirmedInvitations.map(invitation => this.props.createUserEntity({
        variables: {
          createUserEntityMutation: {
            entityId: invitation.entity.id
          }
        }
      }))).then(() => {
        this.props.data.refetch();
        store.snackbar = {
          active: true,
          message: confirmedInvitations.length > 1 ? __('%s people added to your account', confirmedInvitations.length) : __('%s added to your account', confirmedInvitations[0].entity.fullname),
          success: true
        };
      });
    }

    if (noConfirmedInvitations.length) {
      this.sendConfirmation(noConfirmedInvitations[0].type);
      this.setState({ noConfirmedInvitations, step: 1 });
    } else if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  onSelectAll = () => {
    const { data } = this.props;

    data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.viewer.selected = !nextResult.viewer.selected;

      nextResult.viewer.invitations.nodes.forEach((invitation) => {
        invitation.selected = nextResult.viewer.selected;
      });

      return nextResult;
    });
  }

  toggleCheckbox = id => this.props.data.updateQuery((previousResult) => {
    const newInvitations = previousResult.viewer.invitations.nodes.map(node => (node.id !== id ? node : { ...node, selected: !node.selected }));

    return Object.assign({}, previousResult, {
      viewer: { ...previousResult.viewer, invitations: { ...previousResult.viewer.invitations, nodes: newInvitations } },
    });
  })

  renderInvitation = (invitation, key, invitations) => {
    const invitationItem = (<Grid.Column
      data-action="toggle-checkbox"
      data-params={key}
      key={invitation.id}
      onClick={() => this.toggleCheckbox(invitation.id)}
      style={{
        backgroundColor: invitation.selected ? 'rgba(0, 112, 255, 0.06)' : undefined,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 12px'
      }}
    >
      {this.renderEntity(invitation.entity)}
      <ColoredCheckbox checked={invitation.selected} style={{ verticalAlign: 'middle', float: 'right' }} />
    </Grid.Column>);


    if (key === 0 || invitations[key - 1].entity.organization.id !== invitation.entity.organization.id) {
      return [
        <Grid.Column
          key={'org_' + invitation.entity.organization.id}
          style={{
            padding: '10px 12px',
            marginTop: '8px',
            borderBottom: 'solid 1px #f0f1f3',
            color: '#9696a0',
            fontSize: 12
          }}
        >
          <span>{invitation.entity.organization.fullname.toUpperCase()}</span>
        </Grid.Column>,
        invitationItem
      ];
    }

    return invitationItem;
  }

  renderEntity = entity => <div style={{ display: 'flex', alignItems: 'center' }}>
    <Avatar avatar spaced="right" src={entity.picture && entity.picture.uri} alt={entity.fullname} />
    <div>
      {
        entity.fullname && entity.fullname.length > 20 ?
          <Popup
            trigger={<span>{entity.fullname.substring(0, 20).trim()}...</span>}
            content={entity.fullname}
          />
          :
          entity.fullname
      }
      <div style={{ fontSize: '13px', color: '#9696a0' }}>
        {entityTypes(entity.organization.type)[entity.type]}
      </div>
    </div>
  </div>

  render() {
    const { data, store } = this.props;
    const { appends } = store;

    if (((data.loading && !data.viewer) || !data.viewer) && !data.error) return <Modal loading />;

    const { nodes, pageInfo } = data.viewer.invitations;
    const { step } = this.state;

    if (!step) {
      return (
        <Modal
          id="UserInvitations"
          onClose={() => appends.pop()}
          open
          closeIcon="times close"
        >
          <Modal.Header>
            {__('Pending invitations')}
          </Modal.Header>
          <Modal.Content>
            {
              nodes.length ?
                [
                  <div
                    onClick={this.onSelectAll}
                    style={{ color: '#0080ff', padding: '8px 0px 16px 0px' }}
                    className="pointer"
                  >
                    {__('Select All')}
                  </div>,
                  <Grid container columns={1}>
                    {nodes.map(this.renderInvitation)}
                  </Grid>,
                  <Divider hidden />,
                  <Pagination
                    pagination={{
                      graphql: true,
                      hasNextPage: pageInfo && pageInfo.hasNextPage,
                      loading: data.loading
                    }}
                    onLoadMore={() => this.loadMore('invitations', 'viewer')}
                  />
                ]
                :
                <Message content={__('There are no pending invitations')} />
            }
          </Modal.Content>
          {
            nodes.length ?
              <Modal.Actions>
                <Button
                  data-action="submit"
                  primary
                  onClick={this.onSubmit}
                  disabled={!nodes.filter(invitation => invitation.selected).length}
                >
                  {__('Add to your account')}
                </Button>
              </Modal.Actions>
              :
              null
          }
        </Modal>
      );
    }

    const { noConfirmedInvitations, code, attempt, error } = this.state;
    const type = noConfirmedInvitations[0].type;

    return (
      <Modal
        onClose={() => this.props.store.appends.pop()}
        open
        closeIcon="times close"
      >
        <Modal.Header>
          {type === 'PHONE' ? __('We need to confirm your phone') : __('We need to confirm your e-mail')}
        </Modal.Header>
        <Modal.Content>
          {this.renderEntity(noConfirmedInvitations[0].entity)}
          <p style={{ padding: '32px 0px 16px 0px' }}>{type === 'PHONE' ? __('We sent an 6 digits validation code by SMS') : __('We sent an 6 digits validation code by e-mail')}</p>
          <Form.Input
            name="code"
            placeholder={'_ _ _ _ _ _'}
            onChange={(e, d) => this.setState({ code: d.value })}
            value={code}
            autoFocus
            error={!!error}
            style={{ paddingBottom: '16px' }}
          />
          {!!error && <p style={{ color: 'red' }}>{error}</p>}
          {
            type === 'EMAIL' ? null :
              (
                <p style={{ paddingTop: '16px' }}>
                  {
                    attempt < 3 ?
                      [
                        __('Didn\'t receive?'),
                        ' ',
                        <Timer
                          interval={attempt === 1 ? 60 : 120}
                          content={__('Send new SMS')}
                          onClick={() => this.sendConfirmation(type)}
                        />
                      ]
                      :
                      __('In case you didn\'t receive your code yet, please try again later.')
                  }
                </p>
              )
          }
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            disabled={this.state.code.length < 6}
            content={__('Confirm and add to your account')}
            onClick={() => this.confirmAddress(code, noConfirmedInvitations)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

import React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import { Icon, Dropdown, Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';

import Page from '../../components/Page';

import NotificationsDelete from './Notifications/Delete';
import { NotificationItem } from '../../components/ui/NotificationItem';
import { EntityNotificationsViewController as EntityNotifications } from '../../app/modules/Notifications/Entity/Presentation/ViewController';

const PAGE_SIZE = 40;

@inject('store')
@graphql(
  gql`
    query UserNotificationsQuery($limit: Int, $offset: Int, $entityId: ID) {
      viewer {
        id: dbId
        notifications(limit: $limit, offset: $offset, entityId: $entityId) {
          totalCount
          nodes {
            toEntity {
              id: dbId
              fullname
              picture {
                uri
                id: dbId
                key
              }
            }
            fromEntity {
              fullname
              picture {
                id: dbId
                key
                uri
              }
            }
            messageId
            message {
              id: dbId
              moment
            }
            notificationType
            created
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
        notificationsCounter {
          totalCount
        }
        directNotifications(
          limit: $limit
          offset: $offset
          entityId: $entityId
        ) {
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
          totalCount
          nodes {
            toEntity {
              id
              fullname
              picture {
                uri
              }
            }
            fromEntity {
              id
              fullname
              picture {
                uri
              }
            }
            directId
            created
            notificationType
          }
        }
      }
    }
  `,
  {
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        limit: PAGE_SIZE,
        offset: 0,
        entityId: null,
      },
    },
  },
)
@observer
export default class UserNotifications extends Page {
  constructor(props) {
    super(props);

    this.state = {
      entityId: 0,
      cleared: false,
    };
    this.firstLoad = false;
  }

  componentDidUpdate() {
    const { data } = this.props;
    if (!data.loading && !this.firstLoad) {
      this.firstLoad = true;

      const allNotificationsCounter =
        data?.viewer?.notificationsCounter?.totalCount || 0;

      this.props.store.currentUser.notifications = {
        ...data.viewer?.notifications,
        ...data.viewer?.directNotifications,
        totalCount: allNotificationsCounter,
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, visible } = nextProps;

    if ((data.loading && !data.viewer) || !data.viewer) return;

    if (visible && !this.props.visible) {
      setTimeout(() => {
        this.refreshQuery();
      }, 1000);
    } else if (!visible && this.props.visible) {
      this.setState({ entityId: 0 });
    }
  }

  createLink = (notification) => {
    const entityId = notification.toEntity.id;
    const messageId = notification.messageId;
    let node = 'messages';

    if (['LIKE', 'MOMENT'].indexOf(notification.notificationType) > -1) {
      node = 'moments';
    } else if (
      notification.notificationType === 'LOG' &&
      notification.message &&
      notification.message.moment
    ) {
      node = 'moments';
    }

    return `/entities/${entityId}/${node}/${messageId}`;
  };

  renderRow = (notification, key) => (
    <NotificationItem
      createLink={this.createLink}
      key={key}
      notification={notification}
      onClick={this.props.onClose}
      to={this.createLink(notification)}
      locale={this.props.store.app.locale}
    />
  );

  clearNotifications = async () => {
    this.props.store.currentUser.notifications = {
      totalCount: 0,
    };
    this.setState({ cleared: true });
  };

  openDeleteNotifications = () =>
    this.props.store.appends.push(
      <NotificationsDelete clearNotifications={this.clearNotifications} />,
    );

  renderHeader = (loading = false) => {
    const { data, onClose } = this.props;
    const items = [];

    if (!loading && data.viewer.notificationsCounter?.totalCount) {
      items.push(
        <Dropdown.Item
          data-action="clear-notifications"
          onClick={this.openDeleteNotifications}
        >
          {__('Clear all notifications')}
        </Dropdown.Item>,
      );
    }

    items.push(
      <Dropdown.Item
        data-action="open-configuration"
        onClick={() => browserHistory.push('/user/notifications')}
      >
        {__('Notification settings')}
      </Dropdown.Item>,
    );

    return (
      <div
        className="horizontallySpacedItems"
        style={{ height: 64, padding: 20 }}
      >
        <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
          {__('Notifications')}
        </div>
        <div>
          <Dropdown
            icon={null}
            trigger={
              <Icon
                data-action="open-notifications-options"
                name="ellipsis h"
                style={{ color: '#6C6C6C' }}
              />
            }
            direction="left"
          >
            <Dropdown.Menu className="bold">{items}</Dropdown.Menu>
          </Dropdown>
          <Icon
            name="times"
            onClick={onClose}
            className="pointer"
            style={{ marginLeft: 24, color: '#6C6C6C' }}
          />
        </div>
      </div>
    );
  };

  getUserEntities = () => [
    { text: __('All notifications'), value: 0 },
    ...(this.props.store.currentUser
      ? this.props.store.currentUser.entities
      : []
    )
      .filter((e) => e.__typename === 'Entity')
      .map((entity) => {
        const fullname =
          entity.fullname.length > 17
            ? entity.fullname.substring(0, 17).trim() + '...'
            : entity.fullname;
        return {
          text: fullname,
          value: entity.id,
        };
      }),
  ];

  refreshQuery = async () => {
    const { data } = this.props;
    const { entityId } = this.state;

    const result = await data.refetch({
      limit: PAGE_SIZE,
      offset: 0,
      entityId: entityId || null,
    });

    const allNotificationsCounter =
      result?.data?.viewer?.notificationsCounter?.totalCount || 0;

    this.props.store.currentUser.notifications = {
      ...result?.data.viewer.notifications,
      ...result?.data.viewer.directNotifications,
      totalCount: this.state.cleared ? 0 : allNotificationsCounter,
    };

    this.setState({ cleared: false });
  };

  onSelectionChange = (e, { name, value }) => {
    this.setState({ [name]: value }, this.refreshQuery);
  };

  renderOptions = (loading = false) => {
    const { data } = this.props;
    const { entityId } = this.state;

    return (
      <div className="horizontallySpacedItems" style={{ padding: 24 }}>
        <Dropdown
          scrolling
          name="entityId"
          value={entityId}
          options={this.getUserEntities()}
          onChange={this.onSelectionChange}
          selectOnBlur={false}
          selectOnNavigation={false}
          style={{
            boxShadow: '0 0 0 1px rgba(34,36,38,.15) inset',
            padding: '12px 13px',
            borderRadius: 30,
          }}
        />
        <Button
          basic
          onClick={this.refreshQuery}
          style={{ boxShadow: 'none', padding: 0 }}
        >
          <Icon name="sync" loading={loading || data.loading} />
          {loading || data.loading ? __('Refreshing') : __('Refresh')}
        </Button>
      </div>
    );
  };

  render() {
    const { data } = this.props;

    if ((data.loading && !data.viewer) || !data.viewer) {
      return (
        <div id="UserNotifications">
          {this.renderHeader(true)}
          {this.renderOptions(true)}
        </div>
      );
    }

    const { notifications } = data.viewer;
    const { nodes, pageInfo } = notifications;

    return (
      <div
        id="UserNotifications"
        style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}
      >
        {this.renderHeader()}
        {this.renderOptions()}
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EntityNotifications
            notifications={nodes}
            directNotifications={
              this.state.cleared ? [] : data?.viewer?.directNotifications?.nodes
            }
            renderRow={this.renderRow}
            onClose={this.props.onClose}
            pagination={{
              graphql: true,
              hasNextPage: pageInfo && pageInfo.hasNextPage,
              loading: data.loading,
            }}
            directNotificationsPagination={{
              graphql: true,
              hasNextPage:
                data?.viewer?.directNotifications?.pageInfo?.hasNextPage,
              loading: data.loading,
            }}
            onLoadMore={(directsOrOthers) =>
              this.loadMore(directsOrOthers, 'viewer')
            }
          />
        </div>
      </div>
    );
  }
}

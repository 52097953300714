/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Loader } from 'semantic-ui-react';

import Controller from '../../../components/Controller';

import UserAccountForm from './Form';

import i18n, { __ } from '../../../i18n';
import * as utils from '../../../utils';
import * as Analytics from '../../../lib/analytics';

@inject('store')
@graphql(gql`mutation updateUser($updateUserMutation:  UpdateUserInput!) {
  updateUser(input: $updateUserMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['UserNodeQuery', 'AppQuery']
  }
})
@observer
export default class UserAccountEdit extends Controller {
  UNSAFE_componentWillMount() {
    this.props.store.currentEntity = null;
  }

  static fetchData({ store }) {
    store.app.title = __('Edit Account');
  }

  isMessageFromMatriculas = (url) => {
    const formatUrl = url?.endsWith('/') ? url.slice(0, -1) : url;
    const newUrlParts = formatUrl?.split('/');
    const hasMessagesString = newUrlParts?.includes('messages')

    return hasMessagesString && newUrlParts && !isNaN(newUrlParts[newUrlParts.length - 1]);
  }

  request = (values) => {
    const { store } = this.props;
    const props = {};
    const userCameFromMatriculas = this.isMessageFromMatriculas(store.history[store.history?.length - 2]);
    const isUpdatingDocumentNumber = values.documentNumber !== values.oldDocumentNumber;

    if (isUpdatingDocumentNumber) {
      props.documentNumber = values.documentNumber;
    }

    if (values.email !== values.oldEmail) {
      props.email = values.email;
    }

    if (values.phone && (!values.oldPhone || (values.phone.toString().replace(/[^0-9]/g, '') !== values.oldPhone.toString()))) {
      props.phone = values.phone.toString().replace(/[^0-9]/g, '');
      values.phone = props.phone;
    } else if (!values.phone && values.oldPhone) {
      props.phone = null;
    }

    if (values.password !== '') {
      props.password = values.password;
    }

    if (userCameFromMatriculas) {
      Analytics.sendGoogleAnalyticsEvent({
        name: 'user_settings',
        category: 'user came from Matrículas',
      }, { store })
    }

    if (isUpdatingDocumentNumber) {
      if (store.currentUser?.documentNumber === null || '') {
        if (userCameFromMatriculas) {
          Analytics.sendGoogleAnalyticsEvent({
            name: 'user_settings_from_matriculas',
            category: 'user added a new CPF',
          }, { store })
        } else {
          Analytics.sendGoogleAnalyticsEvent({
            name: 'user_settings',
            category: 'user added a new CPF',
          }, { store })
        }
      } else {
        if (userCameFromMatriculas) {
          Analytics.sendGoogleAnalyticsEvent({
            name: 'user_settings_from_matriculas',
            category: 'user updated the CPF',
          }, { store })
        } else {
          Analytics.sendGoogleAnalyticsEvent({
            name: 'user_settings',
            category: 'user updated the CPF',
          }, { store })
        }
      }
    }

    if (store.currentUser?.fullname !== values?.fullname) {
      if (userCameFromMatriculas) {
        Analytics.sendGoogleAnalyticsEvent({
          name: 'user_settings_from_matriculas',
          category: 'user changed the name',
        }, { store })
      } else {
        Analytics.sendGoogleAnalyticsEvent({
          name: 'user_settings',
          category: 'user changed the name',
        }, { store })
      }
    }

    if (store.currentUser?.phone !== values?.phone) {
      if (userCameFromMatriculas) {
        Analytics.sendGoogleAnalyticsEvent({
          name: 'user_settings_from_matriculas',
          category: 'user changed the phone number',
        }, { store })
      } else {
        Analytics.sendGoogleAnalyticsEvent({
          name: 'user_settings',
          category: 'user changed the phone number',
        }, { store })
      }
    }

    if (store.currentUser?.email !== values?.email) {
      if (userCameFromMatriculas) {
        Analytics.sendGoogleAnalyticsEvent({
          name: 'user_settings_from_matriculas',
          category: 'user changed the email',
        }, { store })
      } else {
        Analytics.sendGoogleAnalyticsEvent({
          name: 'user_settings',
          category: 'user changed the email',
        }, { store })
      }
    }

    this.setState({ loadingInfo: true });

    return this.props.mutate({
      variables: {
        updateUserMutation: {
          id: store.currentUser.id,
          fullname: values.fullname,
          language: values.language,
          isNewCode: true,
          ...props
        }
      }
    }).then(() => {
      cookie.save('lang', values.language, { path: '/', maxAge: 60 * 60 * 24 * 7 });
      store.app.locale = values.language;
      i18n.locale = store.app.locale;
      store.currentUser = Object.assign({}, store.currentUser, values);
      store.snackbar = { active: true, message: __('Account settings updated'), success: true };

      this.setState({ loadingInfo: false });

      if (isUpdatingDocumentNumber && this.state.errors?.duplicatedDocumentError) {
        this.setState({
          errors: {
            documentNumber: undefined,
            duplicatedDocumentError: undefined
          },
        });
      }
    }).catch((err) => {
      if (this.isDuplicatedDocumentNumberError(err.graphQLErrors[0]?.stack)) {
        store.currentUser = Object.assign({}, store.currentUser, values);
        this.setState({
          loadingInfo: false,
          errors: {
            documentNumber: __('Invalid CPF'),
            duplicatedDocumentError: utils.handleError(err.graphQLErrors[0])
          }
        });

        return;
      }

      this.setState({ loadingInfo: false });
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    });
  }

  /**
   * @param {string | undefined} errorStack
   * @returns {boolean}
   */
  isDuplicatedDocumentNumberError(errorStack) {
    if (!errorStack) {
      return false;
    }

    return errorStack.includes("[ErrorDuplicatedDocumentNumber]")
  }

  render() {
    let { store } = this.props;

    if (this.state.loadingInfo) return <Loader active />;

    const { email, phone, fullname, language, documentNumber } = store.currentUser;

    return (
      <Controller
        id="UserAccountEdit"
        edit
        title={__('Edit account')}
        form={UserAccountForm}
        values={{
          email: email || '',
          oldEmail: email || '',
          phone: phone || '',
          oldPhone: phone || '',
          fullname,
          documentNumber: documentNumber || '',
          oldDocumentNumber: documentNumber || '',
          language: language && language.length > 2 ? language.substring(0, 2) : language
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}

import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  List,
  Card,
  Icon,
  Accordion,
  Button,
  Label,
  Divider
} from 'semantic-ui-react';
import gql from 'graphql-tag';

import SetUser from '../Admin/SetUser';
import AcceptedTerms from './AcceptedTerms';
import { __ } from '../../i18n';
import * as utils from '../../utils';

const styles = {
  icon: {
    marginLeft: '5px'
  }
}

@inject('store') @observer
export default class UserItem extends React.Component {

  static fragments = {
    user: gql`
      fragment UserItem on User {
        fullname
        id: dbId
        email
        confirmEmail
        phone
        confirmPhone
        tags {
          nodes {
            name
            id: dbId
          }
        }
        devices {
          nodes {
            applicationId
            appVersion
          }
        }
      }`,
  }

  state = {
    showApplications: false,
    showAcceptedTerms: false
  }

  render() {
    const { user, onDelete, onAddTag, onDeleteTag, store, master } = this.props;
    const { currentUser, appends } = store;
    const { fullname, id, email, confirmEmail, phone, confirmPhone, tags, devices } = user;
    const { showApplications, showAcceptedTerms } = this.state;

    return (
      <List.Item
        className="userItem"
        title={fullname}
        data-id={id}
        as={Card}
        style={{ height: '200px', width: '300px', overflowY: 'scroll', margin: '12px 24px 12px 0px' }}
      >
        <Card.Content>
          {onDelete && <Icon data-action="delete" name="trash" link style={{ float: 'right' }} onClick={() => onDelete(user)} />}
          {
            currentUser && currentUser.isMaster &&
            <Icon
              data-action="setuser" name="exchange" style={{ float: 'right' }}
              onClick={() => appends.push(<SetUser value={id} />)}
            />
          }
          <Card.Header title={'ID:' + id}>{fullname}</Card.Header>
          <Card.Meta>ID: {id}</Card.Meta>
          <Card.Description>
            {
              email &&
              <div style={{ marginTop: 7 }}>
                <Icon name="envelope" />
                {utils.renderLongText(email, confirmEmail ? 27 : 32)}
                {confirmEmail ? <Icon name="check" color="green" style={styles.icon} /> : null}
              </div>
            }
            {
              phone &&
              <div style={{ marginTop: 7 }}>
                <Icon name="phone" />
                {utils.renderLongText(phone, confirmPhone ? 27 : 32)}
                {confirmPhone ? <Icon name="check" color="green" style={styles.icon} /> : null}
              </div>
            }
          </Card.Description>
          {
            tags &&
            <Card.Description>
              {
                tags.nodes.map((tag, i) =>
                  <Label className={`tag-${tag.id}`} key={i} basic style={{ marginTop: 7 }}>
                    <Icon name="tag" />
                    {tag.name}
                    {onDeleteTag &&
                      <Icon
                        data-action="delete-tag"
                        name="times"
                        style={{ marginLeft: '10px' }}
                        onClick={() => onDeleteTag(id, tag.id)}
                      />
                    }
                  </Label>
                )
              }
              {
                onAddTag &&
                <Button
                  data-action="add-tag"
                  style={{ marginTop: 7 }}
                  icon="plus"
                  content={__('Add Tag')}
                  size="mini"
                  onClick={() => onAddTag(user)}
                />
              }
            </Card.Description>
          }
          {master && (<Card.Description style={{ marginTop: '10px' }}>
            <Accordion.Title active={showAcceptedTerms} onClick={() => this.setState({ showAcceptedTerms: !showAcceptedTerms })}>
              <Icon size="small" name={showAcceptedTerms ? "dropdown" : "caret right"} style={{ fontWeight: 'bold' }} />
              {__('Terms accepted')}
            </Accordion.Title>
            <Accordion.Content active={showAcceptedTerms}>
              <div>{showAcceptedTerms && (<AcceptedTerms userId={id} />)}</div>
            </Accordion.Content>
          </Card.Description>
          )}
          {
            devices &&
            <Card.Description style={{ marginTop: '10px' }}>
              <Accordion.Title active={showApplications} onClick={() => this.setState({ showApplications: !showApplications })}>
                <Icon size="small" name={showApplications ? "dropdown" : "caret right"} style={{ fontWeight: 'bold' }} />
                {__('Installed applications')}
              </Accordion.Title>
              {(
                showApplications && devices.nodes.length > 0 ?
                  <Accordion>
                    <Accordion.Content active={showApplications}>
                      {devices.nodes.map((device, metaKey) => <Card.Meta key={metaKey}>ClassApp {device.applicationId === 1 ? 'iOS' : 'Android'} {device.appVersion}</Card.Meta>)}
                    </Accordion.Content>
                  </Accordion>
                  :
                  showApplications && <p style={{ color: 'grey' }}>{__('No application installed')}</p>
              )}
            </Card.Description>
          }

        </Card.Content>
      </List.Item>
    );
  }
}

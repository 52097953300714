import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  Label
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';

@inject('store') @observer
@graphql(gql`query userAcceptedTerms ($id: ID!) {
    node(id: $id) @connection(key: "User", filter: ["id"]) {
      ... on User {
        acceptedTerms {
            nodes {
                id
                term {
                    name
                }
            }
          }
      }
    }
  }
`, {
  options: (ownProps, test) => {
    return ({
      variables: {
        id: ownProps.userId
      }
    });
  }
})
export default class UserAcceptedTerms extends React.Component {
  render() {
    const { data, userId } = this.props;
    const { loading, node } = data;

    return loading ? (
      <div>carregando...</div>
    ) : (
      <div>
        {node.acceptedTerms && node.acceptedTerms.nodes && (
          node.acceptedTerms.nodes.length === 0 ? (
            <p style={{ color: 'grey' }}>{__('No terms was accepted!')}</p>
          )
            : node.acceptedTerms.nodes.map(accepted => (
              <div style={{ margin: '1px' }}>
                <Label key={`TermsAccepteds${accepted.id}${userId}`} >{accepted.term.name}</Label>
              </div>
            )))}
      </div>
    );
  }
}

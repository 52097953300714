import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button } from 'semantic-ui-react';

import Modal from '../../../components/Modal';
import { __ } from '../../../i18n';
import { useSnackbar } from '../../../components/kit/Snackbar/hooks/useSnackbar';

@graphql(
  gql`
    mutation clearAllNotifications(
      $clearAllNotificationsMutation: ClearAllNotificationsInput!
    ) {
      clearAllNotifications(input: $clearAllNotificationsMutation) {
        clientMutationId
      }
    }
  `,
  {
    options: {
      refetchQueries: ['UserNotificationsQuery'],
    },
  },
)
@inject('store')
@observer
export default class NotificationsDelete extends React.Component {
  onBack = () => this.props.store.appends.pop();

  request = () =>
    this.props
      .mutate({
        variables: {
          clearAllNotificationsMutation: {
            userId: this.props.store.currentUser.id,
          },
        },
      })
      .then(() => {
        this.props.clearNotifications();
        this.onBack();
        useSnackbar().toast({
          content: (
            <p>
              {__('Your notification list is being cleared.')}{' '}
              <strong>{__('Please wait a few seconds')}</strong>
            </p>
          ),
          type: 'SUCCESS',
        });
      });

  render() {
    return (
      <Modal
        id="NotificationsDelete"
        open
        size="tiny"
        onClose={() => this.onBack()}
      >
        <Modal.Header>{__('Delete notifications')}</Modal.Header>
        <Modal.Content>
          <p>
            {__(
              'This action may change the status of unread messages in your inbox. Once deleted, it can’t be undone.',
            )}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-action="cancel"
            content={__('Cancel')}
            onClick={() => this.onBack()}
            basic
            floated="left"
          />
          <Button
            data-action="submit"
            content={__('Delete')}
            onClick={this.request}
            negative
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

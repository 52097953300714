import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Loader, Header, Icon, Container, Dropdown, Menu, Image } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import CardAdd from '../Card/Add';

import { __ } from '../../i18n';
import { brandToLogo } from '../../lib/payment';

@inject('store')
@graphql(gql`query UserPaymentsQuery {
    viewer {
        id: dbId
        cards {
          nodes {
            id: dbId
            last4Digits
            brand
          }
        }
      }
}
`)
@observer
export default class UserPayments extends React.Component {
  renderRow = (card, key) => {
    const brand = brandToLogo(card.brand || '');

    return (
      <Menu.Item
        key={key}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Header as="h4" style={{ margin: 0 }}>
            <Image style={{ height: 26, width: 26 }} src={brand.noColor} />
            <Header.Content>
              {__('Credit Card')}
              <Header.Subheader>
                {card.brand}{' •••• '}{card.last4Digits.toString().padStart(4, '0')}
              </Header.Subheader>
            </Header.Content>
          </Header>
          <Link to={`/user/payments/${card.id}/delete`} style={{ marginLeft: 'auto' }}>
            <Icon name="trash" style={{ color: '#a0a0a0' }} />
          </Link>
        </div>
      </Menu.Item>
    );
  }

  renderNewCard = () =>
    <Menu.Item
      key={'new-card'}
      onClick={() => this.props.store.appends.push(<CardAdd />)}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Header as="h4" style={{ margin: 0 }}>
          <Icon name="credit card" />
          <Header.Content>
            {__('New credit card')}
          </Header.Content>
        </Header>
        <Icon name="angle right" style={{ marginLeft: 'auto', color: '#a0a0a0' }} />
      </div>
    </Menu.Item>

  render() {
    const { data } = this.props;

    if ((data.loading && !data.viewer) || !data.viewer) return <Loader active inline="centered" />;

    const { cards } = data.viewer;

    return (
      <div id="UserPayments">
        <Container>
          <Header as="h3">
            <Header.Content style={{ fontSize: '16px' }}>
              {__('Payment Methods')}
            </Header.Content>
          </Header>
          <Menu
            vertical
            fluid
            style={{ justifyContent: 'center', marginTop: 0, background: 'white' }}
          >
            {cards.nodes.map((card, i) => this.renderRow(card, i))}
            {this.renderNewCard()}
          </Menu>

        </Container>
        {this.props.children}
      </div>

    );
  }
}

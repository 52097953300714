import React from 'react';
import { Loader, Button, Icon, Image } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import qs from 'qs';
import { inject } from 'mobx-react';

import NewTerms from '../Terms/Index';
import OrganizationItem from '../Organization/Item';
import Responsive from '../../components/Responsive';
import Popup from '../../components/Popup';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import Input from '../../components/ui/Input';

export function isSearchVisible(store) {
  // Definition of project Users: Doni/Rigon/Giovanio e messageria_activesync
  const isProduction = store && store.app && store.app.env === 'production';
  const usersProd = [1302051, 12684554, 12464388, 12365311];
  let isSearchVisible = true;
  if (isProduction) isSearchVisible = !!usersProd.find(u => u === store.currentUser.id);

  return isSearchVisible;
}

@inject('store', 'api', 'client')
@graphql(gql`query UserHomeQuery ($offset: Int, $search: String) {
  viewer {
    id: dbId
    organizations (offset: $offset, search: $search, limit: 150, orderBy: { column: CREATED, direction: ASC}) {
      totalCount
      nodes {
        permissions {
          entityScopes
          hasAdmin
        }
        ... OrganizationItemVertical
      }
    }
  }
}
${OrganizationItem.fragments.vertical}
`, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    variables: {
      offset: parseInt(ownProps.location.query.offset || 0, 10),
      search: ownProps.location.query.search || null
    }
  })
})

export default class UserHome extends Responsive {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      searchText: '',
      lastRequestI: null
    };
  }

  UNSAFE_componentWillMount() {
    this.props.store.currentEntity = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    if ((data.loading && !data.viewer) || !data.viewer) return null;

    this.setState({ data });
  }

  eventReload = (direction) => {
    const { pathname, query, router } = this.props.location;

    if (direction === 'left') {
      query.offset = ((query.offset && parseInt(query.offset, 10)) || 0) - 1;
    } else {
      query.offset = ((query.offset && parseInt(query.offset, 10)) || 0) + 1;
    }

    const params = qs.stringify(query, { arrayFormat: 'brackets', encode: false });

    router.push(pathname + (params ? '?' + params : ''));
  }

  renderRow = (organization, entityPermission) => (<OrganizationItem
    vertical
    mobile={this.isMobile()}
    organization={filter(OrganizationItem.fragments.vertical, organization)}
    isAdmin={organization.permissions && (organization.permissions.hasAdmin || entityPermission.entityScopes.length)}
    to={(organization.permissions && (organization.permissions.hasAdmin || organization.permissions.entityScopes.length)) && `/organizations/${organization.id}`}
  />)

  handleTextChange = (e) => {
    clearTimeout(this.lastRequestId);
    const lastRequestId = setTimeout(() => this.refreshQuery(), 500);
    this.setState({ searchText: e.target.value, lastRequestId, loading: true });
  };

  refreshQuery = () => {
    const { data } = this.props;
    const { searchText } = this.state;

    data.refetch({
      limit: 40,
      offset: 0,
      search: searchText,
    });
  }


  render() {
    const { data, searchText } = this.state;
    const isNewUser = this.props.store.auth.user && this.props.store.auth.user.isNewUser;

    if ((data.loading && !data.viewer) || !data.viewer) return <Loader active centered />;
    return (
      <div id="UserHome" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <NewTerms />
        <Image style={{ marginBottom: '20px', maxWidth: '48px' }} src={utils.asset('/logo/ClassApp-LOGO-SIMBOLO_azul.png')} />
        {isSearchVisible(this.props.store) && <Input
          type="search"
          placeholder={__('Organization search...')}
          onChange={this.handleTextChange}
          value={searchText}
          style={{ width: '400px', marginBottom: '20px' }}
          onClear={() => {
            this.setState({ searchText: '' }, () => this.refreshQuery())
          }}
          iconCircleStyle={{ paddingTop: '14px' }}
        />}
        {data.viewer.organizations.nodes.map((organization, index) => {
          let entityPermission = {};
          if (organization.permissions && organization.permissions.entityScopes && organization.permissions.entityScopes.length) {
            entityPermission = utils.splitPermissionsByFeature(organization.permissions.entityScopes.slice());
          } else {
            entityPermission.entityScopes = organization.permissions.entityScopes;
          }

          return isNewUser && index === 0 ? (<Popup
            name="organizationPopup"
            text={__('Choose one of the profiles from the list and start enjoying better communication at your school! 💙')}
            title={__('Welcome to ClassApp!')}
            showOnStart
            position="left center"
          >
            {this.renderRow(organization, entityPermission)}
          </Popup>) :
            this.renderRow(organization, entityPermission);
        })}
      </div>
    );
  }
}

import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Container, Menu, Button, Loader } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import ListView from '../../components/ListView';
import Page from '../../components/Page';

import { __ } from '../../i18n';

const PAGE_SIZE = 40;

@inject('store')
@graphql(gql`query UserApplicationsQuery (
  $limit: Int,
  $offset: Int
) {
  viewer {
    id: dbId
    applications(limit: $limit, offset: $offset) {
      nodes {
        id: dbId
        name
        clientId
        clientSecret
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
}`, {
  options: {
    variables: {
      limit: PAGE_SIZE,
      offset: 0
    }
  }
})
@observer
export default class UserApplications extends Page {

  renderRow = (application, key) =>
    <div key={key}>
      <p>{__('Name') + ':' + application.name}</p>
      <p>{__('Client ID') + ':' + application.clientId}</p>
      <p>{__('Client Secret') + ':' + application.clientSecret}</p>
      <Button.Group basic>
        <Button as={Link} to={`/applications/${application.id}/edit`} icon="pencil" />
        <Button as={Link} to={`/applications/${application.id}/delete`} icon="trash" />
      </Button.Group>
    </div>

  render() {
    const { data } = this.props;

    if ((data.loading && !data.viewer) || !data.viewer) return <Loader loading />;

    const { nodes, pageInfo } = data.viewer.applications;

    return (
      <Container id="UserApplications">
        <Menu compact>
          <Menu.Item
            content={__('Add application')}
            icon="plus"
            as={Link} to={'/applications/add'}
          />
        </Menu>
        <ListView
          segment={false}
          renderRow={this.renderRow}
          source={nodes}
          pagination={{
            graphql: true,
            hasNextPage: pageInfo && pageInfo.hasNextPage,
            loading: data.loading
          }}
          onLoadMore={() => this.loadMore('applications', 'viewer')}
        />
        {this.props.children}
      </Container>
    );
  }
}

import React from 'react';
import { render, fireEvent, waitFor } from '../../../../newRender';
import { Provider } from 'mobx-react';
import { ApolloProvider } from 'react-apollo';
import UserAccountEdit from '../Edit';
import * as getApplicationsUseCase from '../../../../../client/app/Domain/UseCases/Application/GetApplications'


describe('UserAccountEdit', () => {
  it('should call request function with values', async () => {
    const value = {
      email: 'email@example.com',
      phone: '123456789',
    }
    const store = {
      currentUser: {
        email: 'email@example.com',
      }
    }
    const request = jest.fn().mockImplementation(() => value);
    const { getByText, getByPlaceholderText } = render(<UserAccountEdit request={request} store={store} />);

    const submitButton = getByText('Save');

    const fullnameField = getByPlaceholderText('Full name');
    fireEvent.change(fullnameField, { target: { value: 'New Name' } });

    fireEvent.click(submitButton);

    waitFor(() => {
      expect(request).toHaveBeenCalledWith({
        variables: {
          fullname: 'New Name',
        },
      })
    });
  });

  it('should update the state with the correct error', async () => {
    const props = {
      store: {
        currentUser: {
          email: 'email@example.com',
        },
        history: '',
        app: {
          locale: 'en',
        },
      }
    }

    jest.spyOn(getApplicationsUseCase, 'getApplicationsUseCase').mockResolvedValue({ data: { applications: [] }, error: null });

    const clientMock = {
      mutate: jest.fn().mockImplementation(() => {
        return Promise.reject({
          graphQLErrors: [
            {
              message: "Duplicated CPF Error text. Learn more.",
              name: "GraphQLError",
              locations: [
                {
                  line: 4,
                  column: 5
                }
              ],
              stack: "[ErrorDuplicatedDocumentNumber]GraphQLError: a huge error stack"
            }
          ],
        });
      })
    }

    const mockSetState = jest.spyOn(React.Component.prototype, 'setState');

    const { getByText, getByPlaceholderText } = render(
      <Provider api={{}} store={props.store} client={{}}>
        <ApolloProvider client={clientMock}>
          <UserAccountEdit {...props} />
        </ApolloProvider>
      </Provider>,
    );

    const submitButton = getByText('Save');

    const fullnameField = getByPlaceholderText('Full name');
    fireEvent.change(fullnameField, { target: { value: 'New Name' } });
    fireEvent.click(submitButton);

    await waitFor(() =>
      expect(mockSetState).toHaveBeenCalledWith(
        {
          loadingInfo: false,
          errors: {
            documentNumber: 'Invalid CPF',
            duplicatedDocumentError: 'Duplicated CPF Error text. Learn more.'
          }
        }
      )
    );
  });

  it('should clear the state when there is no error on the second try', async () => {
    const props = {
      store: {
        currentUser: {
          email: 'email@example.com',
        },
        history: '',
        app: {
          locale: 'en',
        },
      }
    }

    jest.spyOn(getApplicationsUseCase, 'getApplicationsUseCase').mockResolvedValue({ data: { applications: [] }, error: null });

    const clientMock = {
      mutate: jest.fn().mockImplementation(() => {
        return Promise.resolve(
          {
            data: {
              updateUser: {
                clientMutationId: null,
                __typename: "UpdateUserPayload"
              }
            }
          }
        );
      })
    }

    const mockSetState = jest.spyOn(React.Component.prototype, 'setState');

    const { getByText, getByPlaceholderText } = render(
      <Provider api={{}} store={props.store} client={{}}>
        <ApolloProvider client={clientMock}>
          <UserAccountEdit {...props} />
        </ApolloProvider>
      </Provider>,
    );

    const submitButton = getByText('Save');
    const fullnameField = getByPlaceholderText('Full name');
    const documentNumber = getByPlaceholderText('___.___.___-__');

    fireEvent.change(fullnameField, { target: { value: 'New Name' } });
    fireEvent.change(documentNumber, { target: { value: '211.657.850-72' } });
    fireEvent.click(submitButton);

    await waitFor(() =>
      expect(mockSetState).toHaveBeenCalledWith(
        {
          errors: {
            documentNumber: undefined,
            duplicatedDocumentError: undefined
          },
        }
      )
    );
  });
});

import React from 'react';
import { inject, observer } from 'mobx-react';
import { Loader } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { browserHistory } from 'react-router';

import { __ } from '../../i18n';
import Container from '../../components/Container';

@inject('store')
@graphql(gql`query UserNodeQuery {
  viewer {
    id: dbId
    fullname
    phone
    email
    documentNumber
    confirmPhone
    confirmEmail
    hasPassword
    isMaster
    language
    emailNtf
    phoneNtf
    webNtf
    ntfAnnouncement
    ntfMessage
    ntfMoment
    ntfMomentReaction
    ntfCommitmentRemind
    ntfReply
    ntfSurvey
    ntfForm
    ntfCommitment
    ntfCharge
    ntfUnread
    ntfArrived
    ntfSurveyPending
  }
}
`, {
  options: {
    fetchPolicy: 'network-only'
  }
})
@observer
export default class UserNode extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;

    if ((data.loading && !data.viewer) || !data.viewer) return;

    const { store } = this.props;

    if (store.currentUser) {
      store.currentUser = Object.assign({}, store.currentUser, data.viewer);
    } else {
      store.currentUser = Object.assign({}, data.viewer);
    }
  }

  render() {
    const { data } = this.props;

    const items =
      [
        { icon: 'user', name: __('Account'), to: '/user/account' },
        { icon: 'lock', name: __('Password'), to: '/user/password' },
        { icon: 'bell', name: __('Notifications'), to: '/user/notifications' },
        { icon: 'credit card', name: __('Payments'), to: '/user/payments' }
      ];


    if ((data.loading && !data.viewer) || !data.viewer) return <Loader active inline="centered" />;

    return (
      <Container desktopItems={items} mobileItems={items} router={browserHistory} headerButton="config" id="UserNode">
        {this.props.children}
      </Container>
    );
  }
}

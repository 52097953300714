import React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Container, Grid, Button, Divider } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import * as utils from '../../utils';

@inject('store')
@graphql(gql`mutation confirmAddress($confirmAddressMutation: ConfirmAddressInput!) {
  confirmAddress(input: $confirmAddressMutation) {
    clientMutationId
  }
}`)
@observer
export default class UserConfirm extends React.Component {

  static fetchData({ store }) {
    store.app.title = __('Address Confirmation');
  }

  state = {
    error: false
  }

  componentDidMount() {
    this.props.mutate({
      variables: {
        confirmAddressMutation: {
          code: this.props.params.code
        }
      }
    }).catch((err) => {
      this.setState({ error: true });
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    });
  }

  render() {
    return (
      <Container id="UserConfirm">
        <Grid stackable>
          <Grid.Column width={4} />
          <Grid.Column stretched width={8}>
            <Container textAlign="center">
              <h1>{__('Address Confirmation')}</h1>
              {
                this.state.error ?
                  <p>{__('An error occured, your address has not been confirmed!')}</p>
                  :
                  <p>{__('Your address has been confirmed!')}</p>
              }
              <Divider hidden section />
              <Button primary content={__('Login')} as={Link} to={'/'} />
            </Container>
          </Grid.Column>
          <Grid.Column width={4} />
        </Grid>
      </Container>
    );
  }
}

import React from 'react';
import * as applicationUseCase from '../../../../app/Domain/UseCases/Application/GetApplications'; // Importar o módulo inteiro

import { render, fireEvent, waitFor } from '../../../../newRender';
import UserAccountForm from '../Form';

describe('UserAccountForm', () => {
    const applicationsUseCaseMock = jest.spyOn(applicationUseCase, 'getApplicationsUseCase')
    const errorText = 'Duplicated Document Error Text';
    const learnMoreText = 'Learn more'

    it('should add an error message in case of duplicated document number', async () => {
        applicationsUseCaseMock
            .mockResolvedValue({
                data: {
                    applications: []
                }
            });

        const { getByText, queryByText } = render(<UserAccountForm errors={
            {
                duplicatedDocumentError: `${errorText}. ${learnMoreText}`,
                documentNumber: 'Invalid CPF'
            }
        } />);

        const errorMessage = getByText('Duplicated Document Error Text.');
        const learnMoreLink = queryByText('Learn more')

        expect(errorMessage).toBeInTheDocument();
        await waitFor(() => {
            expect(learnMoreLink).toBeNull();
        });
    });

    it('should remove the error when update the field value', async () => {
        applicationsUseCaseMock
            .mockResolvedValue({
                data: {
                    applications: []
                }
            });

        const { queryByText, getByPlaceholderText } = render(<UserAccountForm errors={
            {
                duplicatedDocumentError: `${errorText}. ${learnMoreText}`,
                documentNumber: 'Invalid CPF'
            }
        } />);

        const documentNumber = getByPlaceholderText('___.___.___-__');
        fireEvent.change(documentNumber, { target: { value: '211.657.850-72' } });

        const errorMessage = queryByText('Duplicated Document Error Text.');
        const learnMoreLink = queryByText('Learn more')

        expect(errorMessage).toBeNull();
        await waitFor(() => {
            expect(learnMoreLink).toBeNull();
        });
    });

    it('should add a learn more link to the error message in case of duplicated document number and response of applications query', async () => {
        const errorText = 'Duplicated Document Error Text';
        const learnMoreText = 'Learn more'

        jest.spyOn(applicationUseCase, 'getApplicationsUseCase')
            .mockResolvedValue({
                data: {
                    applications: [
                        {
                            id: "123",
                            name: "Duplicate Document Number Prevention",
                            redirectUri: "https://link.de.ajuda.com"
                        }
                    ]
                }
            });


        const { getByText } = render(<UserAccountForm errors={
            {
                duplicatedDocumentError: `${errorText}. ${learnMoreText}`,
                documentNumber: 'Invalid CPF'
            }
        } />);

        expect(getByText('Duplicated Document Error Text.')).toBeInTheDocument();

        await waitFor(() => {
            expect(getByText('Learn more')).toBeInTheDocument();
        });
    });
});

import React from 'react';
import { inject, observer } from 'mobx-react';
import { Divider } from 'semantic-ui-react';

import Form from '../../../components/Form';
import RecoverPassword from '../../../components/RecoverPassword';

import { __ } from '../../../i18n';

@inject('store') @observer
export default class UserPasswordForm extends Form {
  defaultValues = {
    password: '',
    newPassword: '',
    confirmPassword: ''
  }

  rules = {
    newPassword: [
      'required',
      {
        rule: 'minLength:5',
        message: __('Must be at least %s characters long', '5')
      },
      {
        rule: 'matchesField:confirmPassword',
        message: __('New and confirmation password fields must exactly match')
      },
      {
        rule: 'different:password',
        message: __('New and current password fields must be different')
      }
    ],
    confirmPassword: [
      'required',
      {
        rule: 'matchesField:newPassword',
        message: __('New and confirmation password fields must exactly match')
      }
    ]
  }

  maybes = {
    password: ['required', () => this.props.store.currentUser.hasPassword]
  }

  render() {
    const { values, errors } = this.state;

    return (
      <Form id="UserPasswordForm" {...this.props} onSubmit={this.handleSubmit}>
        {
          this.props.store.currentUser.hasPassword ?
            <div>
              <Form.Input
                value={values.password}
                type="password"
                name="password"
                placeholder={__('Type your current password')}
                label={__('Current Password')}
                onChange={this.onInputChange}
                error={errors.password}
                autoFocus
              />
              <a
                data-action="open-recover-password"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.store.appends.push(<RecoverPassword />)}
              >
                {__('Forgot Password?')}
              </a>
            </div>
            :
            null
        }
        <div style={{ marginTop: 20 }}>
          <Form.Input
            value={values.newPassword}
            type="password"
            name="newPassword"
            label={__('New Password')}
            placeholder={__('5 or more characters')}
            onChange={this.onInputChange}
            error={errors.newPassword}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Form.Input
            value={values.confirmPassword}
            type="password"
            name="confirmPassword"
            label={__('Confirm Password')}
            placeholder={__('Same as new password above')}
            onChange={this.onInputChange}
            error={errors.confirmPassword}
          />
        </div>
        <Divider hidden />
      </Form>
    );
  }
}

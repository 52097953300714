import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../../components/Controller';

import UserPasswordForm from './Form';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';

@inject('store')
@graphql(gql`mutation updateUser($updateUserMutation:  UpdateUserInput!) {
  updateUser(input: $updateUserMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['UserNodeQuery', 'AppQuery']
  }
})
@observer
export default class UserPasswordEdit extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Edit Password');
  }

  request = (values) => {
    const { store, router } = this.props;

    return this.props.mutate({
      variables: {
        updateUserMutation: {
          id: store.currentUser.id,
          password: values.password,
          newPassword: values.newPassword,
          isNewCode: true
        }
      }
    }).then(() => {
      store.currentUser = Object.assign({}, store.currentUser, { hasPassword: true });
      store.snackbar = { active: true, message: __('Password settings updated'), success: true };
      router.push('/user/account');
    })
      .catch((err) => {
        store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      });
  }

  render() {
    return (
      <Controller
        id="UserPasswordEdit"
        edit
        form={UserPasswordForm}
        values={{}}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}

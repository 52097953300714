import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Message, Header, Button, Icon } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import InputMask from 'react-input-mask';

import Form from '../../../components/Form';
import PhoneInput from '../../../components/PhoneInput';
import RecoverPassword from '../../../components/RecoverPassword';

import { __, languages } from '../../../i18n';
import { getApplicationsUseCase } from '../../../app/Domain/UseCases/Application/GetApplications';


@inject('store')
@graphql(gql`mutation createConfirmAddress($createConfirmAddressMutation: CreateConfirmAddressInput!) {
  createConfirmAddress(input: $createConfirmAddressMutation) {
    clientMutationId
  }
}`)
@observer
export default class UserAccountForm extends Form {
  constructor(props) {
    super(props);

    this.state.hyperlink = null;
  }

  defaultValues = {
    fullname: '',
    documentNumber: '',
    email: '',
    phone: '',
    language: '',
    password: ''
  }

  rules = {
    email: 'email',
    fullname: 'required',
    phone: 'mobile',
    documentNumber: 'CPF'
  }

  maybes = {
    password: [
      'required', values => (values.phone.toString().replace(/[^0-9]/g, '') !== values.oldPhone) || (values.email !== values.oldEmail) || (values.documentNumber !== values.oldDocumentNumber)
    ],
    phone: [
      'required', values => (values.oldPhone.length > 0) && !values.email
    ],
    email: [
      'required', values => (values.oldEmail.length > 0) && !values.phone
    ],
    documentNumber: [
      'required', values => (values.oldDocumentNumber.length > 0) && !values.documentNumber
    ]
  }

  onDelete = () => {
    console.log('delete');
  }

  confirmDelete = () => this.props.store.prepends.push(
    <Modal
      className="confirm"
      content={
        <Message color="red" >
          <Header
            as="h3"
            icon="exclamation circle"
            content={__('Are you sure you want to delete your account?')}
          />
        </Message>
      }
      actions={
        <Button
          data-action="delete"
          onClick={this.onDelete}
          content={__('Delete')}
          color="red"
        />
      }
    />
  )

  sendConfirmation = (name) => {
    this.props.mutate({
      variables: {
        createConfirmAddressMutation: {
          addressType: name.toUpperCase(),
          isNewCode: true
        }
      }
    }).then(() => {
      this.props.store.snackbar = { active: true, message: __('Confirmation sent'), success: true };
    });
  }

  validateCpf = (cpf) => {
    if (!(/^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/.test(cpf))) {
      throw new Error(__('Invalid CPF'));
    }
  }

  getDuplicateDocumentNumberPreventionApplication = async () => {
    const { data, errors } = await getApplicationsUseCase({ search: "Duplicate Document Number Prevention" });

    if (errors) {
      return;
    }

    if (data.applications && data.applications.length) {
      this.setState({
        hyperlink: data.applications[0].redirectUri
      });
    }
  }

  async componentDidMount() {
    if (this.state.errors?.duplicatedDocumentError) {
      await this.getDuplicateDocumentNumberPreventionApplication();
      return;
    }

    this.setState({
      values: {
        ...this.state.values,
        oldDocumentNumber: this.state.values.documentNumber
      }
    });
  }

  /**
   * @param {string} message
   * @returns {JSX.element}
   */
  enhanceErrorMessage = (message) => {
    const splitText = message.split('.');
    const textMessage = splitText[0];
    const hyperlinkText = splitText[1];

    return (
      <>
        <span style={{ color: '#9f3a38' }} >{`${textMessage}. `}</span>
        {
          this.state.hyperlink &&
          <a
            style={{ textDecorationLine: 'underline' }}
            href={this.state.hyperlink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {hyperlinkText}
          </a>
        }
      </>
    );
  }

  render() {
    const { values, errors } = this.state;

    return (
      <Form id="UserAccountForm" {...this.props} onSubmit={this.handleSubmit}>
        <Form.Input
          value={values.fullname}
          label={__('Full name')}
          name="fullname"
          placeholder={__('Full name')}
          onChange={this.onInputChange}
          error={errors.fullname}
        />
        <InputMask
          mask="999.999.999-99"
          style={{ maxWidth: '524px', height: '38px' }}
          value={values.documentNumber}
          onChange={
            e => {
              if (errors.duplicatedDocumentError) {
                this.setState({
                  errors: {
                    documentNumber: undefined,
                    duplicatedDocumentError: undefined
                  },
                });
              }

              return this.onInputChange(null, e.target);
            }} >
          {
            inputProps =>
              <Form.Input
                label={__('CPF')}
                name="documentNumber"
                {...inputProps}
                placeholder={'___.___.___-__'}
                error={errors.documentNumber}
              />
          }
        </InputMask>
        {
          errors.duplicatedDocumentError &&
          <div style={{ marginTop: '-0.75rem', marginBottom: '1rem', fontSize: '.8125em' }}>
            {this.enhanceErrorMessage(errors.duplicatedDocumentError)}
          </div>
        }
        <Form.Input
          value={values.email}
          label={__('Email')}
          name="email"
          placeholder={__('Email')}
          onChange={this.onInputChange}
          error={errors.email}
        />
        {
          !this.props.store.confirmEmail && values.oldEmail &&
          <Message negative>
            <div>
              <Icon name="exclamation triangle" />
              {__('Email is not confirmed')}
              <Button
                compact
                style={{ marginLeft: '6px' }}
                content={__('Send confirmation email')}
                onClick={() => this.sendConfirmation('email')}
              />
            </div>
          </Message>
        }
        {
          values.oldEmail && values.oldEmail !== values.email &&
          <Message warning>
            <div>
              <Icon name="exclamation triangle" />
              {__('Changing your email will delete all pending invitations related to this email.')}
            </div>
          </Message>
        }
        <Form.Field
          name="phone"
          type="text"
          label={__('Phone')}
          defaultValue={values.phone}
          control={PhoneInput}
          onChange={value => this.onInputChange(null, { name: 'phone', value: value || '' })}
          error={errors.phone}
        />
        {
          !this.props.store.confirmPhone && values.oldPhone &&
          <Message negative>
            <div>
              <Icon name="exclamation triangle" />
              {__('Phone is not confirmed')}
              <Button
                compact
                style={{ marginLeft: '6px' }}
                content={__('Send confirmation SMS')}
                onClick={() => this.sendConfirmation('phone')}
              />
            </div>
          </Message>
        }
        {
          values.oldPhone && values.oldPhone !== values.phone &&
          <Message warning>
            <div>
              <Icon name="exclamation triangle" />
              {__('Changing your phone will delete all pending invitations related to this phone.')}
            </div>
          </Message>
        }
        {
          ((values.phone.toString().replace(/[^0-9]/g, '') !== values.oldPhone) || (values.email !== values.oldEmail) || (values.documentNumber !== values.oldDocumentNumber)) &&
          <div style={{ marginBottom: 15 }}>
            <Form.Input
              type="password"
              value={values.password}
              label={__('Password')}
              name="password"
              placeholder={__('Confirm Password')}
              onChange={this.onInputChange}
              error={errors.password}
            />
            <a style={{ cursor: 'pointer' }} onClick={() => this.props.store.appends.push(<RecoverPassword />)}>{__('Forgot Password?')}</a>
          </div>
        }
        <Form.Dropdown
          selection scrolling
          label={__('Language')}
          name="language"
          value={values.language}
          placeholder={__('Select language')}
          options={languages}
          onChange={this.onSelectionChange}
        />
      </Form>
    );
  }
}

import React from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Grid, Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import omit from 'lodash/omit';

import ColoredCheckbox from '../../../components/ColoredCheckbox';
import Responsive from '../../../components/Responsive';

import { __ } from '../../../i18n';
import * as utils from '../../../utils';

const typeToValue = type => type.replace('Ntf', '').toUpperCase();
const valueToType = value => value.toLowerCase() + 'Ntf';

@inject('store')
@graphql(gql`mutation updateUser($updateUserMutation:  UpdateUserInput!) {
  updateUser(input: $updateUserMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['UserNodeQuery', 'AppQuery']
  }
})
@observer
export default class UserEditNotifications extends Responsive {
  static fetchData({ store }) {
    store.app.title = __('Edit Notifications');
  }

  constructor(props) {
    super(props);
    const currentUser = toJS(props.store.currentUser);
    this.state = {
      emailNtf: false,
      phoneNtf: false,
      webNtf: false,
      ntfAnnouncement: currentUser.ntfAnnouncement,
      ntfMessage: currentUser.ntfMessage,
      ntfMoment: currentUser.ntfMoment,
      ntfCommitmentRemind: currentUser.ntfCommitmentRemind,
      ntfReply: currentUser.ntfReply,
      ntfSurvey: currentUser.ntfSurvey,
      ntfForm: currentUser.ntfForm,
      ntfCommitment: currentUser.ntfCommitment,
      ntfUnread: currentUser.ntfUnread,
      ntfCharge: currentUser.ntfCharge,
      ntfSurveyPending: currentUser.ntfSurveyPending,
      ntfArrived: currentUser.ntfArrived,
      ntfMomentReaction: currentUser.ntfMomentReaction,
    };
  }

  UNSAFE_componentWillMount() {
    const arrays = omit(this.state, ['emailNtf', 'phoneNtf', 'webNtf', 'ntfAnnouncement']);
    this.setState({
      emailNtf: Object.keys(arrays).filter(key => arrays[key].indexOf('EMAIL') !== -1).length > 0,
      phoneNtf: Object.keys(arrays).filter(key => arrays[key].indexOf('PHONE') !== -1).length > 0,
      webNtf: Object.keys(arrays).filter(key => arrays[key].indexOf('WEB') !== -1).length > 0,
    });
  }

  onToggleCheckbox = (type) => {
    const checked = this.state[type];
    this.setState({ [type]: !checked });

    const value = typeToValue(type);
    const arrays = omit(this.state, ['emailNtf', 'phoneNtf', 'webNtf', 'ntfAnnouncement']);

    Object.keys(arrays).forEach((key) => {
      const index = arrays[key].indexOf(value);
      if (checked) {
        if (index !== -1) {
          arrays[key].splice(index, 1);
        }
      } else if (index === -1) {
        arrays[key].push(value);
      }
    });

    this.setState({
      ntfCommitment: arrays.ntfCommitment,
      ntfCommitmentRemind: arrays.ntfCommitmentRemind,
      ntfMessage: arrays.ntfMessage,
      ntfMoment: arrays.ntfMoment,
      ntfReply: arrays.ntfReply,
      ntfSurvey: arrays.ntfSurvey,
      ntfForm: arrays.ntfForm,
      ntfUnread: arrays.ntfUnread,
      ntfAnnouncement: value === 'EMAIL' ? !checked : this.state.ntfAnnouncement,
      ntfCharge: arrays.ntfCharge,
      ntfSurveyPending: arrays.ntfSurveyPending,
      ntfArrived: arrays.ntfArrived,
      ntfMomentReaction: arrays.ntfMomentReaction,
    });
  }

  onChangeCheckbox = (type, value) => {
    if (type !== 'ntfAnnouncement') {
      const currentResult = this.state[type];
      const valueIndex = currentResult.indexOf(value);

      if (valueIndex > -1) {
        currentResult.splice(valueIndex, 1);
      } else {
        currentResult.push(value);
      }

      this.setState({ [type]: currentResult });

      const arrays = omit(this.state, ['emailNtf', 'phoneNtf', 'webNtf', 'ntfAnnouncement']);
      const anySelected = Object.keys(arrays).filter(key => arrays[key].indexOf(value) !== -1).length > 0;
      const mainType = valueToType(value);
      this.setState({
        [mainType]: value === 'EMAIL' ? (anySelected || this.state.ntfAnnouncement) : anySelected,
      });
    } else {
      const arrays = omit(this.state, ['emailNtf', 'phoneNtf', 'webNtf', 'ntfAnnouncement']);
      const anySelected = Object.keys(arrays).filter(key => arrays[key].indexOf(value) !== -1).length > 0;
      this.setState({
        ntfAnnouncement: !this.state.ntfAnnouncement,
        emailNtf: !this.state.ntfAnnouncement || anySelected,
      });
    }
  }

  onSubmit = () => {
    const { store, router } = this.props;

    const content = {
      emailNtf: this.state.emailNtf,
      phoneNtf: this.state.phoneNtf,
      webNtf: this.state.webNtf,
      ntfAnnouncement: this.state.ntfAnnouncement,
      ntfMessage: this.state.ntfMessage,
      ntfMoment: this.state.ntfMoment,
      ntfCommitmentRemind: this.state.ntfCommitmentRemind,
      ntfReply: this.state.ntfReply,
      ntfSurvey: this.state.ntfSurvey,
      ntfForm: this.state.ntfForm,
      ntfCommitment: this.state.ntfCommitment,
      ntfUnread: this.state.ntfUnread,
      ntfCharge: this.state.ntfCharge,
      ntfSurveyPending: this.state.ntfSurveyPending,
      ntfArrived: this.state.ntfArrived,
      ntfMomentReaction: this.state.ntfMomentReaction,
    };

    this.setState({ loading: true });

    this.props.mutate({
      variables: {
        updateUserMutation: {
          id: store.currentUser.id,
          isNewCode: true,
          ...content
        }
      }
    }).then(() => {
      this.setState({ loading: true });
      store.currentUser = Object.assign({}, store.currentUser, content);
      store.snackbar = { active: true, message: __('Notification settings updated'), success: true };
      router.push('/user/account');
    }).catch((err) => {
      this.setState({ loading: true });
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <div id="UserNotificationsEdit" className={this.isMobile() ? 'mobile' : ''} style={{ textAlign: 'center' }}>
        <Grid divided="vertically" >
          <Grid.Row columns={4}>
            <Grid.Column width={6} />
            {
              [__('Email'), __('Phone'), __('Web')].map((title, key) =>
                <Grid.Column textAlign="left" width={2} key={key}>
                  <h3>{title}</h3>
                </Grid.Column>)
            }
          </Grid.Row>

          <Grid.Row columns={4}>
            <Grid.Column textAlign="left" width={6}>
              <p style={{ paddingLeft: '2em' }}>{__('Receive notifications')}</p>
            </Grid.Column>
            {
              ['emailNtf', 'phoneNtf', 'webNtf'].map((name, key) =>
                <Grid.Column textAlign="left" width={2} key={key}>
                  <ColoredCheckbox checked={!!this.state[name]} data-action="select-receive-notification" data-value={name} onClick={() => this.onToggleCheckbox(name)} />
                </Grid.Column>)
            }
          </Grid.Row>

          <Grid.Row columns={4}>
            <Grid.Column textAlign="left" width={6}>
              <p style={{ paddingLeft: '2em' }}>{__('Announcements')}</p>
            </Grid.Column>
            <Grid.Column textAlign="left" width={2}>
              <ColoredCheckbox checked={!!this.state.ntfAnnouncement} data-action="select-receive-announcements" onClick={() => this.onChangeCheckbox('ntfAnnouncement', 'EMAIL')} />
            </Grid.Column>
            <Grid.Column width={2} />
            <Grid.Column width={2} />
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column textAlign="left" width={6}>
              <h3 style={{ paddingLeft: '2em' }}>{__('Notify when...')}</h3>
            </Grid.Column>
            <Grid.Column width={2} />
            <Grid.Column width={2} />
            <Grid.Column width={2} />
          </Grid.Row>

          {
            [
              { title: __('Someone sends a message'), field: 'ntfMessage' },
              { title: __('I have a commitment reminder'), field: 'ntfCommitmentRemind' },
              { title: __('Someone replies'), field: 'ntfReply' },
              { title: __('Someone answers a survey'), field: 'ntfSurvey' },
              { title: __('Someone answers a form'), field: 'ntfForm' },
              { title: __('Someone acknowledges a commitment'), field: 'ntfCommitment' },
              { title: __('Someone pays a charge'), field: 'ntfCharge' },
              { title: __('I have unread messages'), field: 'ntfUnread' },
              { title: __('I have a survey reminder'), field: 'ntfSurveyPending' },
              { title: __('My organization has published a new Moment'), field: 'ntfMoment' },
            ].map((item, k) =>
              <Grid.Row columns={4} key={k}>
                <Grid.Column textAlign="left" width={6}>
                  <p style={{ paddingLeft: '2em' }}>{item.title}</p>
                </Grid.Column>
                {
                  ['EMAIL', 'PHONE', 'WEB'].map(key =>
                    <Grid.Column textAlign="left" width={2} key={key}>
                      <ColoredCheckbox checked={this.state[item.field].includes(key)} data-action={`select-${item.field}`} data-value={key} onClick={() => this.onChangeCheckbox(item.field, key)} />
                    </Grid.Column>)
                }
              </Grid.Row>)
          }

          <Grid.Row columns={4}>
            <Grid.Column textAlign="left" width={6}>
              <p style={{ paddingLeft: '2em' }}>{__('My Moment has received a new like')}</p>
            </Grid.Column>
            <Grid.Column width={2} />
            {
              ['PHONE', 'WEB'].map(key =>
                <Grid.Column textAlign="left" width={2} key={key}>
                  <ColoredCheckbox checked={this.state.ntfMomentReaction.includes(key)} data-action="select-ntfMomentReaction" data-value={key} onClick={() => this.onChangeCheckbox('ntfMomentReaction', key)} />
                </Grid.Column>)
            }
          </Grid.Row>

          <Grid.Row columns={4}>
            <Grid.Column textAlign="left" width={6}>
              <p style={{ paddingLeft: '2em' }}>{__('Arrived notifications')}</p>
            </Grid.Column>
            <Grid.Column width={2} />
            <Grid.Column textAlign="left" width={2} key='PHONE'>
              <ColoredCheckbox checked={this.state.ntfArrived.includes('PHONE')} data-action="select-ntfArrived" data-value="PHONE" onClick={() => this.onChangeCheckbox('ntfArrived', 'PHONE')} />
            </Grid.Column>
            <Grid.Column width={2} />
          </Grid.Row>

        </Grid>
        <Button
          data-action="submit"
          primary style={{ marginTop: 20 }}
          loading={loading} disabled={loading}
          content={__('Save')}
          onClick={this.onSubmit}
        />
      </div>
    );
  }
}
